export default {
  path: '/1024',
  name: '1024',
  redirect: '/1024/introduce',
  children: [
    {
      path: '/1024/introduce',
      name: '1024Introduce',
      component: () => import('@/projects/1024_2022/pc')
    },
    {
      path: '/1024/m/introduce',
      name: '1024Introduce_m',
      component: () => import('@/projects/1024_2022/mobile')
    }
  ]
}
