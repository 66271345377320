export default {
  path: '/intel',
  name: 'Intel',
  redirect: '/intel/index',
  children: [
    {
      path: '/intel/index',
      name: 'Index',
      component: () => import('@/projects/Intel/pages/index/pc')
    },
    {
      path: '/intel/m/index',
      name: 'IndexM',
      component: () => import('@/projects/Intel/pages/index/mobile')
    },
    {
      path: '/intel/apply',
      name: 'Apply',
      component: () => import('@/projects/Intel/pages/apply')
    },
    {
      path: '/intel/m/index',
      name: 'ApplyM',
      component: () => import('@/projects/Intel/pages/apply')
    },
    {
      path: '/intel/signUp',
      name: 'SignUp',
      component: () => import('@/common/intelSignUp/pc')
    },
    {
      path: '/intel/m/signUp',
      name: 'SignUpM',
      component: () => import('@/common/intelSignUp/mobile')
    }
  ]
}
