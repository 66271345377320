export default {
  path: '/m/zone/qualcomm2016/wos',
  name: 'Qualcomm',
  redirect: '/m/zone/qualcomm2016/wos/index',
  children: [
    {
      path: '/m/zone/qualcomm2016/wos/index',
      name: 'QualcommIndex',
      component: () => import('@/projects/Qualcomm/pages/index')
    },
    {
      path: '/m/zone/qualcomm2016/wos/tools',
      name: 'QualcommTools',
      component: () => import('@/projects/Qualcomm/pages/tools')
    },
    {
      path: '/m/zone/qualcomm2016/wos/docs',
      name: 'QualcommDocs',
      component: () => import('@/projects/Qualcomm/pages/docs')
    },
    {
      path: '/m/zone/qualcomm2016/wos/support',
      name: 'QualcommSupport',
      component: () => import('@/projects/Qualcomm/pages/support')
    },
    {
      path: '/m/zone/qualcomm2016/wos/resources',
      name: 'QualcommResources',
      component: () => import('@/projects/Qualcomm/pages/resources')
    }
  ]
}
