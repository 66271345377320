export default {
  path: '/intelOpenCv',
  name: 'IntelOpenCv',
  redirect: '/intelOpenCv/index',
  children: [
    {
      path: '/intelOpenCv/index',
      name: 'IntelOpenCvIndex',
      component: () => import('@/projects/IntelOpenCv/index/pc')
    },
    {
      path: '/intelOpenCv/m/index',
      name: 'IntelOpenCvIndexM',
      component: () => import('@/projects/IntelOpenCv/index/mobile')
    },
    {
      path: '/intelOpenCv/signUp',
      name: 'IntelOpenCvSignUp',
      component: () => import('@/projects/IntelOpenCv/signUp/pc')
    },
    {
      path: '/intelOpenCv/m/signUp',
      name: 'IntelOpenCvSignUpM',
      component: () => import('@/projects/IntelOpenCv/signUp/mobile')
    },
  ]
}
