export default {
  path: '/intelOpenCvSenior',
  name: 'IntelOpenCvSenior',
  redirect: '/intelOpenCvSenior/index',
  children: [
    {
      path: '/intelOpenCvSenior/index',
      name: 'IntelOpenCvSeniorIndex',
      component: () => import('@/projects/IntelOpenCvSenior/index/pc')
    },
    {
      path: '/intelOpenCvSenior/m/index',
      name: 'IntelOpenCvSeniorIndexM',
      component: () => import('@/projects/IntelOpenCvSenior/index/mobile')
    },
    {
      path: '/intelOpenCvSenior/signUp',
      name: 'IntelOpenCvSeniorSignUp',
      component: () => import('@/projects/IntelOpenCvSenior/signUp/pc')
    },
    {
      path: '/intelOpenCvSenior/m/signUp',
      name: 'IntelOpenCvSeniorSignUpM',
      component: () => import('@/projects/IntelOpenCvSenior/signUp/mobile')
    },
  ]
}
