export default {
  path: '/consensys',
  name: 'Consensys',
  redirect: '/consensys/index',
  children: [
    {
      path: '/consensys/index',
      name: 'ConsensysIndex',
      component: () => import('@/projects/Consensys/pages/Index')
    },
    {
      path: '/consensys/apply',
      name: 'ConsensysApply',
      component: () => import('@/projects/Consensys/pages/Apply')
    },
    {
      path: '/consensys/activity',
      name: 'ConsensysActivity',
      component: () => import('@/projects/Consensys/pages/Activity')
    },
    {
      path: '/consensys/news',
      name: 'ConsensysNews',
      redirect: '/consensys/news/list',
      children: [
        {
          path: '/consensys/news/list',
          name: 'ConsensysNewsList',
          component: () => import('@/projects/Consensys/pages/News')
        },
        {
          path: '/consensys/news/:id',
          name: 'ConsensysNewsDetail',
          component: () => import('@/projects/Consensys/pages/News/Detail')
        },
      ]
    },
    {
      path: '/consensys/video',
      name: 'video',
      redirect: '/consensys/video/list',
      children: [
        {
          path: '/consensys/video/list',
          name: 'ConsensysVideoList',
          component: () => import('@/projects/Consensys/pages/Video'),
        },
        {
          path: '/consensys/video/:id',
          name: 'ConsensysVideoDetail',
          component: () => import('@/projects/Consensys/pages/Video/Detail'),
        },
      ]
    },
    {
      path: '/consensys/about',
      name: 'about',
      redirect: '/consensys/about/ambassador',
      children: [
        {
          path: '/consensys/about/ambassador',
          name: 'ConsensysAmbassador',
          component: () => import('@/projects/Consensys/pages/About/Ambassador')
        },
        {
          path: '/consensys/about/aurora',
          name: 'ConsensysAurora',
          component: () => import('@/projects/Consensys/pages/About/Aurora')
        },
        {
          path: '/consensys/about/ipfs',
          name: 'ConsensysIPFS',
          component: () => import('@/projects/Consensys/pages/About/IPFS')
        },
        {
          path: '/consensys/about/ethereum',
          name: 'ConsensysEthereum',
          component: () => import('@/projects/Consensys/pages/About/Ethereum')
        },
        {
          path: '/consensys/about/transaction',
          name: 'ConsensysTransaction',
          component: () => import('@/projects/Consensys/pages/About/Transaction')
        },
        {
          path: '/consensys/about/nft',
          name: 'ConsensysNFT',
          component: () => import('@/projects/Consensys/pages/About/NFT')
        },
        {
          path: '/consensys/about/starknet',
          name: 'ConsensysStarknet',
          component: () => import('@/projects/Consensys/pages/About/Starknet')
        },
        {
          path: '/consensys/about/near',
          name: 'ConsensysNear',
          component: () => import('@/projects/Consensys/pages/About/Near')
        },
        {
          path: '/consensys/about/avalanche',
          name: 'ConsensysAvalanche',
          component: () => import('@/projects/Consensys/pages/About/Avalanche')
        },
        {
          path: '/consensys/about/optimism',
          name: 'ConsensysOptimism',
          component: () => import('@/projects/Consensys/pages/About/Optimism')
        },
      ]
    },
    {
      path: '/consensys/celo',
      name: 'celo',
      component: () => import('@/projects/Consensys/pages/Celo'),
    },
    {
      path: '/consensys/allLink',
      name: 'allLink',
      component: () => import('@/projects/Consensys/pages/AllLink'),
    },
    {
      path: '/consensys/DiscordGuide',
      name: 'discordGuide',
      component: () => import('@/projects/Consensys/pages/DiscordGuide'),
    },
    {
      path: '/consensys/Arbitrum',
      name: 'Arbitrum',
      component: () => import('@/projects/Consensys/pages/Arbitrum'),
    },
    {
      path: '/consensys/Filecoin',
      name: 'Filecoin',
      component: () => import('@/projects/Consensys/pages/Filecoin'),
    },
    {
      path: '/consensys/PoS',
      name: 'PoS',
      component: () => import('@/projects/Consensys/pages/PoS'),
    },
    {
      path: '/consensys/linea',
      name: 'Linea',
      component: () => import('@/projects/Consensys/pages/Linea'),
    },
  ]
}
