export default {
  path: '/aws',
  name: 'Aws',
  redirect: '/aws/index',
  children: [
    {
      path: '/aws/index',
      name: 'index',
      component: () => import('@/projects/Aws/pages/index/pc')
    },
    {
      path: '/aws/signUp',
      name: 'signUp',
      component: () => import('@/projects/Aws/pages/signUp/pc')
    },
    {
      path: '/aws/m/index',
      name: 'indexM',
      component: () => import('@/projects/Aws/pages/index/mobile')
    },
    {
      path: '/aws/m/signUp',
      name: 'signUpM',
      component: () => import('@/projects/Aws/pages/signUp/pc')
    },
    {
      path: '/aws/signIn',
      name: 'signIn',
      component: () => import('@/projects/Aws/pages/index/pc/signIn')
    },
    {
      path: '/aws/m/signIn',
      name: 'signInM',
      component: () => import('@/projects/Aws/pages/index/mobile/signIn')
    }
  ]
}