import { createRouter, createWebHistory } from 'vue-router'

let routes = []
if(window.location.origin.includes('intel.csdn.net')) { // intel.csdn.net域名下配置特殊路由
  routes = [require('./unique/intel').default]
} else {
  // 自动引入./modules下的路由配置
  const modules = require.context('./modules', false, /\.js$/)
  routes = modules.keys().map(key => modules(key).default)
}

const router = createRouter({
  history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
  routes
})

export default router
