export default {
  path: '/IntelRealTimeJunior',
  name: 'IntelRealTimeJunior',
  redirect: '/IntelRealTimeJunior/index',
  children: [
    {
      path: '/IntelRealTimeJunior/index',
      name: 'IntelRealTimeJunior',
      component: () => import('@/projects/IntelRealTimeJunior/index/pc')
    },
    {
      path: '/IntelRealTimeJunior/m/index',
      name: 'IntelRealTimeJuniorM',
      component: () => import('@/projects/IntelRealTimeJunior/index/mobile')
    },
    {
      path: '/IntelRealTimeJunior/signUp',
      name: 'IntelRealTimeJuniorSignUp',
      component: () => import('@/projects/IntelRealTimeJunior/signUp/pc')
    },
    {
      path: '/IntelRealTimeJunior/m/signUp',
      name: 'IntelRealTimeJuniorSignUpM',
      component: () => import('@/projects/IntelRealTimeJunior/signUp/mobile')
    },
  ]
}
