export default {
  path: '/TencentTrainingCamp',
  name: 'TencentTrainingCamp',
  redirect: '/TencentTrainingCamp/index',
  children: [
    {
      path: '/TencentTrainingCamp/index',
      name: 'TencentTrainingCampIndex',
      component: () => import('@/projects/TencentTrainingCamp/page/index/pc')
    },
    {
      path: '/TencentTrainingCamp/m/index',
      name: 'TencentTrainingCampIndexM',
      component: () => import('@/projects/TencentTrainingCamp/page/index/mobile')
    },
    {
      path: '/TencentTrainingCamp/firstPhase',
      name: 'TencentTrainingCampFirstPhase',
      component: () => import('@/projects/TencentTrainingCamp/page/firstPhase/pc')
    },
    {
      path: '/TencentTrainingCamp/m/firstPhase',
      name: 'TencentTrainingCampFirstPhaseM',
      component: () => import('@/projects/TencentTrainingCamp/page/firstPhase/mobile')
    },
    {
      path: '/TencentTrainingCamp/signUp',
      name: 'TencentTrainingCampSignUp',
      component: () => import('@/projects/TencentTrainingCamp/page/signUp/pc')
    },
    {
      path: '/TencentTrainingCamp/m/signUp',
      name: 'TencentTrainingCampSignUpM',
      component: () => import('@/projects/TencentTrainingCamp/page/signUp/mobile')
    },
    {
      path: '/TencentTrainingCamp/secondPhase',
      name: 'TencentTrainingCampSecondPhase',
      component: () => import('@/projects/TencentTrainingCamp/page/secondPhase/pc')
    },
    {
      path: '/TencentTrainingCamp/m/secondPhase',
      name: 'TencentTrainingCampSecondPhaseM',
      component: () => import('@/projects/TencentTrainingCamp/page/secondPhase/mobile')
    },
    {
      path: '/TencentTrainingCamp/thirdPhase',
      name: 'TencentTrainingCampThirdPhase',
      component: () => import('@/projects/TencentTrainingCamp/page/thirdPhase/pc')
    },
    {
      path: '/TencentTrainingCamp/m/thirdPhase',
      name: 'TencentTrainingCampThirdPhaseM',
      component: () => import('@/projects/TencentTrainingCamp/page/thirdPhase/mobile')
    },
    {
      path: '/TencentTrainingCamp/fourthPhase',
      name: 'TencentTrainingCampFourthPhase',
      component: () => import('@/projects/TencentTrainingCamp/page/fourthPhase/pc')
    },
    {
      path: '/TencentTrainingCamp/m/fourthPhase',
      name: 'TencentTrainingCampFourthPhaseM',
      component: () => import('@/projects/TencentTrainingCamp/page/fourthPhase/mobile')
    },
    {
      path: '/TencentTrainingCamp/fifthPhase',
      name: 'TencentTrainingCampFifthPhase',
      component: () => import('@/projects/TencentTrainingCamp/page/fifthPhase/pc')
    },
    {
      path: '/TencentTrainingCamp/m/fifthPhase',
      name: 'TencentTrainingCampFifthPhaseM',
      component: () => import('@/projects/TencentTrainingCamp/page/fifthPhase/mobile')
    },
    {
      path: '/TencentTrainingCamp/sixthPhase',
      name: 'TencentTrainingCampSixthPhase',
      component: () => import('@/projects/TencentTrainingCamp/page/sixthPhase/pc')
    },
    {
      path: '/TencentTrainingCamp/m/sixthPhase',
      name: 'TencentTrainingCampSixthPhaseM',
      component: () => import('@/projects/TencentTrainingCamp/page/sixthPhase/mobile')
    },
  ]
}
