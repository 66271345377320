export default {
  path: '/IntelRealTimeSenior',
  name: 'IntelRealTimeSenior',
  redirect: '/IntelRealTimeSenior/index',
  children: [
    {
      path: '/IntelRealTimeSenior/index',
      name: 'IntelRealTimeSenior',
      component: () => import('@/projects/IntelRealTimeSenior/index/pc')
    },
    {
      path: '/IntelRealTimeSenior/m/index',
      name: 'IntelRealTimeSeniorM',
      component: () => import('@/projects/IntelRealTimeSenior/index/mobile')
    },
    {
      path: '/IntelRealTimeSenior/signUp',
      name: 'IntelRealTimeSeniorSignUp',
      component: () => import('@/projects/IntelRealTimeSenior/signUp/pc')
    },
    {
      path: '/IntelRealTimeSenior/m/signUp',
      name: 'IntelRealTimeSeniorSignUpM',
      component: () => import('@/projects/IntelRealTimeSenior/signUp/mobile')
    },
  ]
}
