export default {
  path: '/intelsmartedge',
  name: 'Intelsmartedge',
  redirect: '/intelsmartedge/index',
  children: [
    {
      path: '/intelsmartedge/index',
      name: 'Intelsmartedge',
      component: () => import('@/projects/Intelsmartedge/index/pc')
    },
    {
      path: '/intelsmartedge/m/index',
      name: 'IntelsmartedgeM',
      component: () => import('@/projects/Intelsmartedge/index/mobile')
    },
    {
      path: '/intelsmartedge/signUp',
      name: 'IntelsmartedgeSignUp',
      component: () => import('@/projects/Intelsmartedge/signUp/pc')
    },
    {
      path: '/intelsmartedge/m/signUp',
      name: 'IntelsmartedgeSignUpM',
      component: () => import('@/projects/Intelsmartedge/signUp/mobile')
    },
  ]
}
