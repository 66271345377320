export default {
  path: '/',
  name: 'Intel',
  children: [
    {
      path: '/',
      name: 'Index',
      component: () => import('@/projects/Intel/pages/index/pc')
    },
    {
      path: '/m',
      name: 'IndexM',
      component: () => import('@/projects/Intel/pages/index/mobile')
    },
    {
      path: '/apply',
      name: 'Apply',
      component: () => import('@/projects/Intel/pages/apply')
    },
    {
      path: '/m/apply',
      name: 'ApplyM',
      component: () => import('@/projects/Intel/pages/apply')
    },
    {
      path: '/techList/openvino',
      name: 'Openvino',
      meta: {pageName : 'openvino'},
      component: () => import('@/projects/Intel/pages/techList/openvino/pc')
    },
    {
      path: '/m/techList/openvino',
      name: 'OpenvinoM',
      meta: {pageName : 'openvino'},
      component: () => import('@/projects/Intel/pages/techList/openvino/mobile')
    },
    {
      path: '/techList/devcloud',
      name: 'Devcloud',
      meta: {pageName : 'devcloud'},
      component: () => import('@/projects/Intel/pages/techList/openvino/pc')
    },
    {
      path: '/m/techList/devcloud',
      name: 'DevcloudM',
      meta: {pageName : 'devcloud'},
      component: () => import('@/projects/Intel/pages/techList/openvino/mobile')
    },
    {
      path: '/techList/EIIECI',
      name: 'EIIECI',
      meta: {pageName : 'EIIECI'},
      component: () => import('@/projects/Intel/pages/techList/openvino/pc')
    },
    {
      path: '/m/techList/EIIECI',
      name: 'EIIECIM',
      meta: {pageName : 'EIIECI'},
      component: () => import('@/projects/Intel/pages/techList/openvino/mobile')
    },
    {
      path: '/techList/LLM',
      name: 'LLM',
      meta: {pageName : 'LLM'},
      component: () => import('@/projects/Intel/pages/techList/openvino/pc')
    },
    {
      path: '/m/techList/LLM',
      name: 'LLMM',
      meta: {pageName : 'LLM'},
      component: () => import('@/projects/Intel/pages/techList/openvino/mobile')
    },
    {
      path: '/devkit/guide',
      name: 'KitGuide',
      component: () => import('@/projects/Intel/pages/kit/guide/pc')
    },
    {
      path: '/m/devkit/guide',
      name: 'KitGuideM',
      component: () => import('@/projects/Intel/pages/kit/guide/mobile')
    },
    {
      path: '/devkit/exhibition',
      name: 'KitExhibition',
      component: () => import('@/projects/Intel/pages/kit/exhibition/pc')
    },
    {
      path: '/m/devkit/exhibition',
      name: 'KitExhibitionM',
      component: () => import('@/projects/Intel/pages/kit/exhibition/mobile')
    },
    {
      path: '/recentEvents',
      name: 'ActivityList',
      component: () => import('@/projects/Intel/pages/activityList/pc')
    },
    {
      path: '/m/recentEvents',
      name: 'ActivityListM',
      component: () => import('@/projects/Intel/pages/activityList/mobile')
    },
    {
      path: '/videoSeminar',
      name: 'VideoList',
      component: () => import('@/projects/Intel/pages/videoList/pc')
    },
    {
      path: '/m/videoSeminar',
      name: 'VideoListM',
      component: () => import('@/projects/Intel/pages/videoList/mobile')
    },
    {
      path: '/openSource/openvino',
      name: 'OpenSourceOpenvino',
      component: () => import('@/projects/Intel/pages/openSource/openvino/pc')
    },
    {
      path: '/m/openSource/openvino',
      name: 'OpenSourceOpenvinoM',
      component: () => import('@/projects/Intel/pages/openSource/openvino/mobile')
    },
    {
      path: '/openSource/devcloud',
      name: 'OpenSourceDevcloud',
      component: () => import('@/projects/Intel/pages/openSource/devcloud/pc')
    },
    {
      path: '/m/openSource/devcloud',
      name: 'OpenSourceDevcloudM',
      component: () => import('@/projects/Intel/pages/openSource/devcloud/mobile')
    },
    {
      path: '/openSource/EIIECI',
      name: 'OpenSourceEIIECI',
      component: () => import('@/projects/Intel/pages/openSource/EIIECI/pc')
    },
    {
      path: '/m/openSource/EIIECI',
      name: 'OpenSourceEIIECIM',
      component: () => import('@/projects/Intel/pages/openSource/EIIECI/mobile')
    },
  ]
}