export default {
  path: '/IntelRealTimeIntermediate',
  name: 'IntelRealTimeIntermediate',
  redirect: '/IntelRealTimeIntermediate/index',
  children: [
    {
      path: '/IntelRealTimeIntermediate/index',
      name: 'IntelRealTimeIntermediate',
      component: () => import('@/projects/IntelRealTimeIntermediate/index/pc')
    },
    {
      path: '/IntelRealTimeIntermediate/m/index',
      name: 'IntelRealTimeIntermediateM',
      component: () => import('@/projects/IntelRealTimeIntermediate/index/mobile')
    },
    {
      path: '/IntelRealTimeIntermediate/signUp',
      name: 'IntelRealTimeIntermediateSignUp',
      component: () => import('@/projects/IntelRealTimeIntermediate/signUp/pc')
    },
    {
      path: '/IntelRealTimeIntermediate/m/signUp',
      name: 'IntelRealTimeIntermediateSignUpM',
      component: () => import('@/projects/IntelRealTimeIntermediate/signUp/mobile')
    },
  ]
}
